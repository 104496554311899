import Img from 'gatsby-image'
import styled from 'styled-components'

import media from './../../style/media'

export default styled(Img)`
  height: 70px;
  width: 70px;
  border-radius: 50%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.21);
  ${media.phone`
    width: 60px;
    height: 60px;
  `};
`
