import React, { Component } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import ScrollTo from 'react-scroll-into-view'

// UI
import { Container, Flex } from '../../style/global'
import { fontPrimary, colorPrimary, colorWhite } from '../../style/variables'
import media from '../../style/media'
import LogoImage from './logo'

import SubHeader from './subheader'

const FlexExtended = styled(Flex)`
  flex: 1;
  > h1 {
    margin: 0 0 0 30px;
    font-size: 30px;
    font-family: ${fontPrimary};
    color: #000;
  }
  > h2 {
    margin: 0 0 0 30px;
    font-size: 20px;
    font-family: ${fontPrimary};
    color: rgba(0, 0, 0, 0.3);
  }
  ${media.phone`
    > h1 {
    margin: 0 0 0 10px;
    font-size: 20px;
    font-family: ${fontPrimary};
    text-align: center;
    }
    > h2 {
    margin: 0 0 0 10px;
    font-size: 15px;
    font-family: ${fontPrimary};
    color: rgba(0, 0, 0, 0.3);
    text-align: center;

  }
  `};
`

const Logo = styled(Link)`
  text-decoration: none;
  flex: 1;
  display: flex;
  align-items: center;

  ${media.phone`
    display: flex;
  `};
`

const LogoWrapper = styled.div`
  text-decoration: none;
  flex: 3;
  ${media.phone`
    display: flex;
    align-items: center;
    z-index: 100;
  `};
`

const NavLink = styled.a`
  text-decoration: none;
  padding: 0 0 0 15px;
  font-family: ${fontPrimary};
  color: ${colorPrimary};
  font-size: 16px;
  cursor: pointer;
  position: relative;
  transition: transform 0.5s;
  transition-timing-function: ease;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  &:before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    opacity: 0.3;
    background: #000;
    transform: scale3d(0, 1, 1);
    transform-origin: 0% 50%;
    transition: transform 0.5s;
    transition-timing-function: ease;
    transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  }
  &:hover {
    &:after {
      transform: scale3d(1, 1, 1);
    }
  }
  ${media.phone`
    display: block;
    padding: 10px 0 10px 15px;
  `};
`

const Navigation = styled.nav`
  display: flex;
  flex: 4;
  justify-content: flex-end;
  align-items: center;
  ${media.phone`
    position: fixed;
    z-index: 50;
    width 100%;
    background: #fff;
    flex-direction: column; 
    justify-content: flex-start;
    align-items: flex-start;
    top: 79px;
    left: 0;
    padding: 20px 0px;
    transition: all 0.3s ease-out;
    transform: ${({ toggled }) =>
      toggled ? 'translateY(0px)' : 'translateY(-400px)'};
    opacity: ${({ toggled }) => (toggled ? '1' : '0')};
  `};
`

const Head = styled.header`
  padding: 10px 0px;
  background: ${colorWhite};
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
`

const MenuToggle = styled.div`
  display: none;
  ${media.phone`
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
    flex-direction: column;
    height: 40px;
    width: 40px;
    z-index: 200;
    > div {
      height: 3px;
      background: #000;
      transition: all 0.2s ease-out;
      width: ${({ toggled }) => (toggled ? '20%' : '100%')};
    }
  `};
`

export default class Header extends Component {
  state = {
    toggled: false,
  }

  toggle = () => {
    this.setState(({ toggled }) => ({
      toggled: !toggled,
    }))
  }

  render() {
    return (
      <>
        <Head>
          <Container>
            <Flex res="background: #fff; z-index: 90;">
              <LogoWrapper>
                <Logo to="/">
                  <LogoImage />
                  <FlexExtended column>
                    <h1>Sewa Physiotherapy</h1>
                    <h2>Massage & Chiropractor Clinic</h2>
                  </FlexExtended>
                </Logo>
                <MenuToggle onClick={this.toggle} toggled={this.state.toggled}>
                  <div />
                  <div />
                </MenuToggle>
              </LogoWrapper>
              <Navigation toggled={this.state.toggled}>
                <ScrollTo alignToTop selector="#about-us">
                  <NavLink onClick={this.toggle}>About Us</NavLink>
                </ScrollTo>
                <ScrollTo alignToTop selector="#testimonials">
                  <NavLink onClick={this.toggle}>Testimonials</NavLink>
                </ScrollTo>
                <ScrollTo alignToTop selector="#departments">
                  <NavLink onClick={this.toggle}>Departments</NavLink>
                </ScrollTo>
                <ScrollTo alignToTop selector="#team">
                  <NavLink onClick={this.toggle}>Team</NavLink>
                </ScrollTo>
                <ScrollTo alignToTop selector="#gallery">
                  <NavLink onClick={this.toggle}>Gallery</NavLink>
                </ScrollTo>
                <ScrollTo alignToTop selector="#appointment-form">
                  <NavLink onClick={this.toggle}>Appointment Form</NavLink>
                </ScrollTo>
                <ScrollTo alignToTop selector="#contact-us">
                  <NavLink onClick={this.toggle}>Contact Us</NavLink>
                </ScrollTo>
              </Navigation>
            </Flex>
          </Container>
        </Head>
        <SubHeader />
      </>
    )
  }
}
