import React, { Component } from 'react'
import styled from 'styled-components'
import ScrollTo from 'react-scroll-into-view'

// UI
import { Container, Flex } from '../../style/global'
import { fontPrimary, colorPrimary } from '../../style/variables'
import media from '../../style/media'

//  Icons
import { PhoneCall } from 'styled-icons/feather/PhoneCall.cjs'
import { Clock } from 'styled-icons/feather/Clock.cjs'
import { Wpforms } from 'styled-icons/fa-brands/Wpforms'

const Wrapper = styled.div`
  background: ${colorPrimary};
  padding: 15px 0px;
  margin-top: 85px;
  z-index: 50;
  ${media.phone`
    margin-top: 80px;
  `};
`
const Phone = styled(PhoneCall)`
  margin-right: 20px;
  height: 30px;
`
const StyledClock = styled(Clock)`
  margin-right: 20px;
  height: 30px;
`
const Form = styled(Wpforms)`
  margin-right: 20px;
  height: 30px;
`

const A = styled.a`
  font-family: ${fontPrimary};
  color: white;
  font-size: 14px;
  display: flex;
  align-items: center;
  ${media.phone`
    display: none;
  `};
`

const Div = styled.div`
  font-family: ${fontPrimary};
  color: white;
  font-size: 14px;
  text-decoration: underline;
  display: flex;
  align-items: center;
`

const StyledScroll = styled(ScrollTo)`
  display: flex;
  align-items: center;
  cursor: pointer;
  ${media.phone`
    display: none;
  `};
`

export default class SubHeader extends Component {
  render() {
    return (
      <Wrapper>
        <Container>
          <Flex justify="space-evenly" res="align-items: center;">
            <Div>
              <A href="tel:5877074466">
                <Phone />
                587-707-4466
              </A>
            </Div>
            <Div>
              <StyledClock />
              <div>
                Mon to Fri: 9 AM to 8 PM <br />
                Sat & Sun: 10 AM to 5 PM
              </div>
            </Div>
            <Div>
              <StyledScroll alignToTop selector="#appointment-form">
                <Form />
                Request appointment
              </StyledScroll>
            </Div>
          </Flex>
        </Container>
      </Wrapper>
    )
  }
}
